import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Main from "./components/Main";
import Ecoamigables from "./components/Ecoamigables";
import Personalizados from "./components/Personalizados";
import Materiales from "./components/Materiales";
import Sanarte from "./components/Sanarte";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/Ecoamigables" element={<Ecoamigables />} />
        <Route path="/Ecoamigables/:imageId" element={<Ecoamigables />} />
        <Route path="/Personalizados" element={<Personalizados />} />
        <Route path="/Personalizados/:imageId" element={<Personalizados />} />
        <Route path="/Materiales" element={<Materiales />} />
        <Route path="/Materiales/:imageId" element={<Materiales />} />
        <Route path="/Sanarte" element={<Sanarte />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
