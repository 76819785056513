import "../stylesheets/Main.css";
import "../stylesheets/Materiales.css";
import { FaArrowDown } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Image } from "./image_gallery/Image";
import { srcCollage } from "../data/image_db";
import { Modal } from "./image_gallery/Modal";
import { useParams, useNavigate } from "react-router-dom";

function Materiales() {
  const [isOpen, setIsOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [isModalHidden, setIsModalHidden] = useState(true);
  const navigate = useNavigate();
  const { imageId } = useParams();

  useEffect(() => {
    if (imageId) {
      const imgIndex = parseInt(imageId, 10);
      setImageSrc(srcCollage[imgIndex]);
      setIsModalHidden(false);
    } else {
      setIsModalHidden(true);
    }
  }, [imageId]);
  
  const openImg = (e) => {
    const imgIndex = srcCollage.indexOf(e.target.src);
    setImageSrc(e.target.src);
    setIsModalHidden(false);
    navigate(`/materiales/${imgIndex}`);
  };

  const closeImg = (e) => {    
    setIsModalHidden(true);
    navigate("/materiales");
  };

  const currentIndex = srcCollage.findIndex((img) => img === imageSrc);

  const prev = () => {
    const prevIndex = (currentIndex - 1 + srcCollage.length) % srcCollage.length;
    setImageSrc(srcCollage[prevIndex]);
    navigate(`/materiales/${prevIndex}`);
  };

  const next = () => {
    const nextIndex = (currentIndex + 1) % srcCollage.length;
    setImageSrc(srcCollage[nextIndex]);
    navigate(`/materiales/${nextIndex}`);
  };

  const OpenList = () => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <div className="main-gy-container">
      <h2>Materiales</h2>
      <p>Nuestros trabajados se realizan todos en base a materia recuperada.</p>
      <p>
        Evitando el residuo de obsoletos, generamos una nueva identidad al
        objeto original extendiendo su vida útil y compartiendo la idea de
        consumo responsable.
      </p>
      <p>
        Desde nuestro lugar, te invitamos a co-crear una mejor calidad de vida
        acrecentando el impacto positivo de la mano a la evolución de nuestra
        especie.
      </p>
      <div className="gy-container gy-list">
        {srcCollage.map((image, index) => {
          return <Image key={index} src={image} openImg={openImg} />;
        })}
      </div>
      <Modal
        isHidden={isModalHidden}
        imageSrc={imageSrc}
        closeImg={closeImg}
        handlePrevious={prev}
        handleNext={next}
      />
      <div className="list-box">
        <div className="list_title" onClick={OpenList}>
          <FaArrowDown />
          <h4>Algunos materiales de los que recibimos</h4>
          <FaArrowDown />
        </div>
        <ul className={isOpen ? "ul_style ul_style_show" : "ul_style"}>
          <li>Diapositivas.</li>
          <li>Disquetes.</li>
          <li>Casetes de Audio.</li>
          <li>Casete mini de contestador y grabadora.</li>
          <li>Discos de vinilo simples y LP.</li>
          <li>Contenedores de golosinas plásticos; ej: tic-tac.</li>
          <li>Capsulas de café de aluminio.</li>
          <h4>Consultar por otros materiales que estén fuera de la lista.</h4>
        </ul>
      </div>
    </div>
  );
}

export default Materiales;
