import { useEffect, useState } from "react";
import { Image } from "./image_gallery/Image";
import { srcEcoamigables } from "../data/image_db";
import { Modal } from "./image_gallery/Modal";
import { useParams, useNavigate } from "react-router-dom";

function Ecoamigables() {
  const [imageSrc, setImageSrc] = useState(null);
  const [isModalHidden, setIsModalHidden] = useState(true);
  const navigate = useNavigate();
  const { imageId } = useParams();

  useEffect(() => {
      if (imageId) {
        const imgIndex = parseInt(imageId, 10);
        setImageSrc(srcEcoamigables[imgIndex]);
        setIsModalHidden(false);
      } else {
        setIsModalHidden(true);
      }
    }, [imageId]);

  const openImg = (e) => {
    const imgIndex = srcEcoamigables.indexOf(e.target.src);
    setImageSrc(e.target.src);
    setIsModalHidden(false);
    navigate(`/ecoamigables/${imgIndex}`);
  };

  const closeImg = (e) => {
    setIsModalHidden(true);
    navigate("/ecoamigables");
  };

  const currentIndex = srcEcoamigables.findIndex((img) => img === imageSrc);

  const prev = () => {
    const prevIndex = (currentIndex - 1 + srcEcoamigables.length) % srcEcoamigables.length;
    setImageSrc(srcEcoamigables[prevIndex]);
    navigate(`/ecoamigables/${prevIndex}`);
  };

  const next = () => {
    const nextIndex = (currentIndex + 1) % srcEcoamigables.length;
    setImageSrc(srcEcoamigables[nextIndex]);
    navigate(`/ecoamigables/${nextIndex}`);
  };

  return (
    <div className="main-gy-container">
      <h1>Ecoamigables</h1>
      <p>Te ofrecemos productos utilitarios.</p>
      <p>
        Sencillos, cómodos, prácticos, económicos y amigables con el ecosistema.
      </p>
      <div className="gy-container">
        {srcEcoamigables.map((img, index) => {
          return <Image key={index} src={img} openImg={openImg} />;
        })}
      </div>
      <Modal
        isHidden={isModalHidden}
        imageSrc={imageSrc}
        closeImg={closeImg}
        handlePrevious={prev}
        handleNext={next}
      />
    </div>
  );
}

export default Ecoamigables;
