import { useEffect, useState } from "react";
import { srcCassettes } from "../data/image_db";
import "../stylesheets/Ecoamigables.css";
import { Image } from "./image_gallery/Image";
import { Modal } from "./image_gallery/Modal";
import { useParams, useNavigate } from "react-router-dom";

function Personalizados() {
  const [imageSrc, setImageSrc] = useState(null);
  const [isModalHidden, setIsModalHidden] = useState(true);
  const navigate = useNavigate();
  const { imageId } = useParams();

  useEffect(() => {
    if (imageId) {
      const imgIndex = parseInt(imageId, 10);
      setImageSrc(srcCassettes[imgIndex]);
      setIsModalHidden(false);
    } else {
      setIsModalHidden(true);
    }
  }, [imageId]);

  const openImg = (e) => {
    const imgIndex = srcCassettes.indexOf(e.target.src);
    setImageSrc(e.target.src);
    setIsModalHidden(false);
    navigate(`/personalizados/${imgIndex}`);
  };

  const closeImg = (e) => {
    setIsModalHidden(true);
    navigate("/personalizados");
  };

  const currentIndex = srcCassettes.findIndex((img) => img === imageSrc);

  const prev = () => {
    const prevIndex = (currentIndex - 1 + srcCassettes.length) % srcCassettes.length;
    setImageSrc(srcCassettes[prevIndex]);
    navigate(`/personalizados/${prevIndex}`);
  };

  const next = () => {
    const nextIndex = (currentIndex + 1) % srcCassettes.length;
    setImageSrc(srcCassettes[nextIndex]);
    navigate(`/personalizados/${nextIndex}`);
  };

  return (
    <div className="main-gy-container">
      <h1>Personalizados</h1>
      <p>
        Brindamos, también, la posibilidad de recrearlos a elección del
        consumidor.
      </p>
      <p>
        Tanto en los colores para las terminaciones y en la materia prima para
        gusto personal, como en la elección de personajes, banda musical o
        evento particular en que elijas representar con cada una de nuestras
        propuestas.
      </p>
      <div className="gy-container">
        {
          srcCassettes.map((image, index) => {
            return <Image key={index} src={image} openImg={openImg} />
          })
        }
      </div>
      <Modal
        isHidden={isModalHidden}
        imageSrc={imageSrc}
        closeImg={closeImg}
        handlePrevious={prev}
        handleNext={next}
      />
    </div>
  );
}

export default Personalizados;
