const collage1 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726730/collage1_a5sqvg.jpg";
const collage2 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726728/collage2_eiho6t.jpg";
const collage3 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726728/collage3_r9faci.jpg";
const collage4 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726729/collage4_zlx7bj.jpg";

const vinilo1 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726732/vinilo1_f5ymcu.jpg";
const vinilo2 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726734/vinilo2_hafx7k.jpg";
const ecoamigables1 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726730/ecoamigables1_qocm1q.png";
const vinilo4 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726741/vinilo4_dbntyb.jpg";
const ecoamigables2 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726730/ecoamigables2_z8s3rr.png";
const vinilo6 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726742/vinilo6_axnlfd.jpg";
const ecoamigables4 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726731/ecoamigables4_cwwdkk.png";
const ecoamigables3 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726731/ecoamigables3_w5x2oe.png";


const cassette1 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726728/cassette1_xzzlyp.webp";
const cassette2 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726731/cassette2_pylbov.jpg";
const cassette3 = "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726731/cassette3_mg1gvc.jpg";

export const srcCassettes = [cassette1, cassette2, cassette3];

export const srcCollage = [collage1, collage2, collage3, collage4];

export const srcEcoamigables = [
  vinilo1,
  vinilo2,
  ecoamigables1,
  vinilo4,
  ecoamigables2,
  vinilo6,
  ecoamigables4,
  ecoamigables3,
];
